<template>
	<a-config-provider :locale="locale">
		<main style="height: 100vh" class="body-container">
            <div v-if="!!loading" class="loading">
                <div class="overlay-background-wrapper"></div>
                <div class="overlay-content-wrapper">
                    <a-spin />
                </div>
            </div>
            <app-header /> 
            <div class="main-container">
                <div v-if="!isPriviledgesDenied">
                    <div class="left-container">
                        <div class="menu-container">
							<AppMenu :t="t" />
						</div>
                    </div>
                </div>
                <a-layout v-if="!isPriviledgesDenied && !isPageAccessDenied" style="flex: 1;">
                    <a-layout-header class="content-layout-header-wrapper" :style="{ backgroundColor: '#fff', paddingInline: '20px', height: '50px', borderBottom: 'solid 0.5px #eee'}" >
                        <page-breadcrumb />
                    </a-layout-header>
                    <a-layout-content :style="{ minHeight: 'calc(100vh - 90px)', backgroundColor:'#F0F2F5'}" id="stickyBox">
                        <router-view :key="route.fullPath" />
                    </a-layout-content>
                </a-layout>
                <a-layout class="no-priviledges-tips-wrapper" v-if="!!isPriviledgesDenied || !!isPageAccessDenied">
                    <a-layout-content style="display:flex;align-items:center;justify-content:center;">
                        {{ t('common.noPriviledges') }}
                    </a-layout-content>
                </a-layout>
            </div>
        </main>
	</a-config-provider>
</template>

<script>
import AppHeader from '@/components/header-wrapper';
import PageBreadcrumb from '@/components/layout-breadcrumb-wrapper';
import AppMenu from '@/components/menus-wrapper';
import languageJSON from "@/lang/index";
import { setIntl } from '@/utils/enumOptsUtils';
import locale from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { computed, reactive, toRefs } from 'vue';
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
	components: { 
		AppHeader,
		AppMenu,
		PageBreadcrumb
	},
	setup() {
		const store = useStore();
		const route = useRoute();
        const { t } = useI18n({ messages: languageJSON })
		const state = reactive({
            locale,
            collapsed: JSON.parse(localStorage.getItem("menusCollapsed") || false),
			loading: computed(() => store.state.loading),
			historyRoutes: computed(() => store.state.historyRoutes),
            isPriviledgesDenied: computed(() => store.state.isPriviledgesDenied),
			isPageAccessDenied: computed(() => store.state.isPageAccessDenied),
		})
        setIntl(t);
        dayjs.locale('zh-cn');

		return {
			t,
			route,
			...toRefs(state)
		}
	}
}
</script>


<style lang="less">
.content-layout-header-wrapper {
    background: #fff; 
    padding: 0; 
    display: flex; 
    height: 40px; 
    line-height: 40px; 
    justify-content: space-between; 
    // border-bottom: 1px solid #f0f0f0;
    // box-shadow: 0 2px 18px 0 rgba(49,24,16,10%);
    z-index: 2;
}
.menu-item {
    &:hover {
        background-color: #1890ff;
        cursor: pointer;
        text-decoration: none;
    }
}

.menu-container {
    height: 100%;
}

.left-container {
    height: 100%; 
}

.menu-botom {
    display: block;
    width: 100%;
    height: 56px;

    .live-icon {
        width: 50px;
        height: 50px;
        line-height: 50px;
        color: #999;
        cursor: pointer;
        font-size: 20px;
        text-align: center;
        padding: 4px;

        &:hover {
            color: #1890ff;
        }
    }
}

.content-layout-header-wrapper .trigger {
  font-size: 18px;
  padding: 0 10px;
  cursor: pointer;
  transition: color 0.3s;
}

.content-layout-header-wrapper .trigger:hover,
.content-layout-header-wrapper .anticon-menu-fold {
  color: #1890ff;
}

:deep(ant-layout-header) {
	background: #fff;
}

.body-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
	background: @background;

    .main-container {
        display: flex;
        flex: 1;
        color: @textColor;
    }
    .loading {
        position: fixed;
        top: 0;
        bottom:0;
        left: 0;
        right: 0;
        z-index: 10000;
        display: flex;
        align-items: center;
        justify-content: center;
        .overlay-background-wrapper {
            position: absolute;
            top: 0;
            bottom:0;
            left: 0;
            right: 0;
            background: rgba(0,0,0,0.25);
            z-index: 10001;
        }
        .overlay-content-wrapper {
            position: relative;
            text-align: center;
            z-index: 10002;
        }
    }
}

</style>
